.image-carousel-container {
    background-color: var(--secondary-orange);
    flex-basis: 70%;
    width: 70%;
}

.image-carousel-container .carousel-item {
    text-align: center;
    height: 400px;
}

.carousel-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.carousel-title {
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to top, #00000079, rgba(255, 255, 255, 0.377));
    font-size: 1.25rem;
}
.carousel-title-text{
    color:#faebd7;
    padding-bottom:5px;
    text-shadow:2px 2px black;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #faebd7;
}

.carousel-control-prev-icon {
    background-color: rgb(0, 0, 0, 0.7);
}
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (min-width:1300px)
{
	.image-carousel-container .carousel-item {
		height: 420px;
	}
}

@media screen and (max-width: 900px) {
    .image-carousel-container {
        flex-basis: 100%;
        width: 100%;
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 7%;
    }
}

@media screen and (max-width: 540px) {
	.carousel-image
	{
		object-fit:unset;
	}
	
	.image-carousel-container .carousel-item {
		height: 300px;
	}
	
	.carousel-control-prev,
    .carousel-control-next {
        width: 10%;
    }
}
