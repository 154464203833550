#nav-bar {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 45px;
    background-color: var(--primary-blue);
    top: 0;
    left: 0;
    right: 0;
}

#dt-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
}

#mb-nav {
    display: none;
}

#mb-nav h3 {
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 1.25rem;
    color: white;
}

#nav-bar.fixed {
    position: fixed;
}

.nav-item {
    flex-grow: 1;
    text-decoration: none;
}

.nav-item > div:nth-child(1) {
    flex-grow: 1;
    color: white;
    font-weight: bold;
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.nav-item > div:nth-child(1) span {
    flex-grow: 1;
    line-height: 43px;
}

.nav-item .hover-line {
    height: 2px;
    background-color: var(--primary-orange);
    width: 0px;
    transition: width 0.2s ease-in-out;
}

.nav-item:hover .hover-line {
    width: 100%;
}

.nav-item:hover .nav-dropdown-container {
    display: flex;
}

.nav-dropdown-container {
    z-index: 10;
    position: absolute;
    display: none;
    flex-direction: column;
}
.nav-dropdown-item:hover {
    background-color: var(--secondary-orange);
}

.nav-dropdown-item {
    cursor: pointer;
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
    background-color: white;
    color: var(--primary-blue);
    font-weight: bold;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.nav-dropdown-container a .nav-dropdown-item {
    border-top: 1px solid gray;
}

.nav-dropdown-container a:first-child .nav-dropdown-item {
    border-top: none;
}

.nav-dropdown-container.multiple {
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 300px;
    width: 100%;
    left: 0;
    padding: 20px 40px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 900px) {
    #dt-nav {
        display: none;
    }

    #mb-nav {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction:row-reverse;
    }
}
