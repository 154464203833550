.introduction-page
{
	display:flex;
}



.paragraph-container
{
	margin:20px auto;
}

.themes-container
{
	display:flex;
	flex-wrap:wrap;
	flex-direction:row;
	justify-content:space-between;
	
}
.theme-image-holder
{
	display:flex;
	flex-direction:column;
	width:30%;
	transition: all 0.15s ease-in-out;
	margin-top:10px;
	margin-bottom:10px;
	text-align:center;
	margin:10px auto;
}

.theme-image-holder:hover
{
	transform: translateY(-5px) scale(1.07);
	
}
.theme-image-holder-image img
{
	border-radius:10px;
	object-fit: cover;
	width: 100%;
	height:220px;
	box-shadow: 1px 1px 10px 1px #979595;
}
.theme-image-holder-link
{
	text-decoration:none;
	margin:10px auto;	
	color:var(--primary-orange);
	font-weight:500;
	font-size:1.10rem;
}
.theme-image-holder:hover .theme-image-holder-link
{
	color:var(--primary-orange);
	text-decoration:underline;
	text-decoration-color: var(--primary-blue);
	text-decoration-thickness: 2px;
}

.progress-container
{
	display:flex;
	flex-wrap:wrap;
	flex-direction:row;
	justify-content:space-between;
}

.progress-details
{
	display:flex;
	flex-direction:column;
	width:30%;
	margin-top:10px;
	margin-bottom:10px;
	text-align:center;
	margin:10px auto;
}

.progress-icon
{
	
	border-radius:10px;
	width:150px;
	height:150px;
}

.progress-link
{
	text-decoration:none;
	margin:10px auto;	
	color:var(--primary-orange);
	font-weight:500;
	font-size:1.10rem;
}

.progress-link:hover
{
	color:var(--primary-orange);
	text-decoration:underline;
	text-decoration-color: var(--primary-blue);
	text-decoration-thickness: 2px;
}

@media screen and (max-width:900px)
{
	
	
	.theme-image-holder,.progress-details
	{
		width:45%;
		
	}
	
	.theme-image-holder-image img
	{
		
		height:180px;
	}
	
}

@media screen and (max-width:540px){	
	.theme-image-holder,.progress-details
	{
		width:100%;
	}
	
	.theme-image-holder img
	{
		
		height:220px;
	}
	
}
