.FaqBox-accordion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FaqBox-accordion-Qn {
  display: flex;
  justify-content: space-between;
  background: #8db3b09c;
  cursor: pointer;
  padding: 14px 14px;
  width: 70%;
  outline: none;
  transition: 0.4s;
  border-radius: 4px;
  color: #052447;
  font-weight: bold;
}
.FaqBox-accordion-Qn p {
  width: 70%;
  margin-bottom: 0;
}

.FaqBox-accordion-Qn span {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FaqBox-accordion-Qn:hover {
  background-color: #ccdad9;
}

.FaqBox-sign {
  transition: all 0.8s;
}

.FaqBox-accordion-Ans {
  width: 70%;
  background: #ccdad9;
  border-top: 2px solid #052f5f;
  border-bottom: 2px solid #052f5f;
  overflow: hidden;
  transition: all 0.5s;
  display: block;
  padding: 0;
  opacity: 0;
  height: 0px;
  text-align: justify;
  font-size: 16px;
}

.Faqs-tabs {
  display: flex;
  justify-content: center;
  transition: all 0.5s;
}

.Faqs-tabs span {
  margin: 0 10px;
  color: var(--primary-blue);
  cursor: pointer;
}

.FaqBox-accordion a {
  text-decoration: none;
}

.FaqBox-accordion li {
  text-align: justify;
  font-size:inherit;
}

.FaqGI-Intern-container {
  display: flex;
  flex-direction: column;
}
.FaqGI-Intern-img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 900px) {
  .FaqBox-accordion-Qn {
    width: 90%;
  }
  .FaqBox-accordion-Ans {
    width: 90%;
    font-size: 18px;
  }
}
@media screen and (max-width: 560px) {
  .FaqBox-accordion-Qn {
    width: 100%;
  }
  .FaqBox-accordion-Qn p {
    font-size: 16px;
  }

  .FaqBox-accordion-Qn span {
    width: 10%;
    font-size: 16px;
  }
  .FaqBox-accordion-Ans {
    width: 100%;
    font-size: 14px;
  }
}
