html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  --primary-orange: #fe901f;
  --primary-blue: #052f5f;
  --secondary-orange: #f9b81f;
  --primary-green: #ccdad9;
}
body {
  margin: 0;
  font-family: "Quattrocento Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
}

p,
li {
  font-size: 1rem;
  line-height: 1.5rem;
}

/* For pages and sidebar */
.page-heading {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 20px auto;
  text-align: center;
  color: var(--primary-blue);
}

.page-sub-heading {
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: var(--primary-blue);
}

.paragraph-content {
  margin: 20px auto;
}

.side {
  left: 0;
  float: left;
  overflow-x: hidden;
  box-shadow: 3px 0px 5px 3px rgba(0, 0, 0, 0.25);
  width: 50px;
  background-color: var(--secondary-orange);
}

@media screen and (max-width: 900px) {
  .side {
    width: 20px;
  }
}

@media screen and (max-width: 540px) {
  .side {
    width: 0px;
  }
}
