#nav-drawer-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    z-index: 10000;
    transition: 0.2s;
}

#nav-drawer > h3 {
    padding: 0px 10px;
    position: fixed;
    right: 7vw;
    top: 0;
    color: white;
    font-family: "Quattrocento Sans";
    font-size: 2rem;
    line-height: 55px;
    margin: 0;
    cursor: pointer;
}

#nav-drawer {
    height: 100%;
    min-width: 265px;
    max-width: calc(100vw - 40px);
    width: 80vw;
    background-color: white;
    box-shadow: 3px 0px 5px 3px rgba(0, 0, 0, 0.25);
    transition: 0.2s;
}

#nav-drawer .header {
    height: 55px;
    padding: 0.75rem;
    background-color: var(--primary-blue);
    color: white;
    text-align: center;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#nav-drawer ul {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 10px 0px;
    margin: 0;
    list-style-type: none;
    color: black;
    transition: 0.25s;
    overflow: auto;
}

#nav-drawer li:not(.nav-drawer-heading) {
    font-size: 1rem;
    cursor: pointer;
}

#nav-drawer li:hover:not(.nav-drawer-heading) {
    background-color: rgba(0, 0, 0, 0.1);
}

#nav-drawer li > div,
#nav-drawer li a > div {
    padding: 0.75rem 1.5rem 0.75rem 2.5rem;
}

#nav-drawer a{
    color: black;
    text-decoration: none;
}

.nav-drawer-link {
    display: flex;
    align-items: center;
}

.nav-drawer-link svg {
    margin-left: auto;
}

#nav-drawer .body {
    display: flex;
    position: relative;
    overflow-x: hidden;
    height: calc(100% - 55px);
}

#nav-drawer .nav-line {
    padding: 0;
    margin: 0.2rem 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
}

#nav-drawer .nav-drawer-heading h3 {
    font-size: 1.25rem;
    margin: 0;
}

#nav-drawer .header h3 {
    margin: 0;
}

#nav-drawer.translate-left h3{
      left: -100%
}

.translate-left {
    transform: translateX(-100%);
}

.translate-right {
    transform: translateX(100%);
}
