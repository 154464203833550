.adopted-villages
{
	display:flex;
}

.village-heading
{
	margin:0 auto;
	width:fit-content;
	color:var(--primary-blue);
	border-bottom:4px solid var(--primary-orange);
	margin-bottom:20px;
}

.village-heading h2
{
	letter-spacing: 3px;
	text-shadow: 3px 3px 4px #b5d6d3;
	margin-bottom:.2rem;
}

.village-map
{
  border: 2px solid #CCDAD9;
  border-radius:10px;
  width: 65%;
  height: 450px;
  margin: 20px auto;
  display: block;
  box-shadow: 1px 4px 8px 2px rgba(0, 0, 0, 0.2);
}

.census-link {
  text-decoration: none;
  color: var(--primary-blue);
  font-weight:bold;
}

.census-link:hover {
  text-decoration: underline;
  color: var(--primary-blue);
  text-decoration-color: var(--secondary-orange);
}

.census-link
{
	margin:10px auto;
}

.census-link-icon
{
  transition: all 0.15s ease-in-out;
  font-weight:bold;
}

.census-link:hover .census-link-icon
{
	transform: translateY(-8px) scale(1.1);
}

.about-coordinates
{	
	font-weight:bold;
	color:var(--primary-blue);
}

@media screen and (max-width: 840px) {
	.village-map
	{
		width:90%;
		height:350px;
	}
}
@media screen and (max-width: 540px) {
	
	.village-map
	{
		width:100%;
		height:300px;
	}
}



