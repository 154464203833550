.report-activity
{
	width:100%;
	margin:40px auto;
}

.sub-heading
{
	margin:20px auto;
}
.sub-line-heading {
  width: fit-content;
}

.sub-line-heading{
  text-transform: uppercase;
  color: var(--primary-blue);
  margin-bottom: 0.75rem;
  position: relative;
  font-weight:500;
}

.sub-line-heading:after {
  position: absolute;
  content: "";
  height: 3.5px;
  bottom: -3px;
  margin: 0 auto;
  left: 0;
  width: 50%;
  background: var(--secondary-orange);
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sub-line-heading:hover:after {
  width: 100%;
  background: var(--secondary-orange);
}

.sub-line-paragraph
{
	margin:auto 20px;
}

.carousel-slide-image
{
	width:100%;
	height:450px;
	object-fit:fill;
	cursor:pointer;
	transition:all 0.15s ease-in-out;
}
.carousel-image-holder
{	
	width:100%;
	margin:auto 10px;
	object-fit:fill;
}

.non-carousel-image
{
	width:100%;
	margin:auto 0;
	height:450px;
	object-fit:cover;
	cursor:pointer;
	transition:all 0.15s ease-in-out;
}

.non-carousel-image-holder
{
	height:450px;
	overflow:hidden;
}

.non-carousel-image:hover,.carousel-slide-image:hover
{
	transform:scale(1.1);
}
.report-flex-holder
{
	display:flex;
}

.left-side-content
{
	width:55%;
}

.right-side-content
{
	width:45%;
}

.carousel-control-prev,.carousel-control-next {
    width: 7%;
}
@media screen and (max-width: 840px)  {

	.left-side-content
	{
		width:100%;
	}
	
	.right-side-content
	{
		width:90%;
		margin:0 auto;
	}
	
	.report-flex-holder
	{
		flex-direction:column-reverse;
	}
	.carousel-slide-image,.carousel-image-holder
	{
		height:400px;
	}
	
	.carousel-image-holder
	{
		width:90%;
	}
	
	.non-carousel-image
	{
		width:90%;
		height:400px;
	}
	
}
@media screen and (max-width: 540px)  {
	
	.right-side-content
	{
		width:100%;
	}
	.carousel-slide-image,.carousel-image-holder
	{
		width:100%;
		height:300px;
		margin:0;
	}
	
	.non-carousel-image
	{
		width:100%;
		height:300px;
		margin:0;
	}
}
